import { FC } from 'react';
import { Chip as MuiChip } from '@mui/material';
import classNames from 'classnames';

import './Chip.scss';

interface ChipWrapperProps {
  label: string;
  wrapperClassname?: string;
}

const Chip: FC<ChipWrapperProps> = ({ label, wrapperClassname = '' }) => (
  <div className={classNames('chip', wrapperClassname)}>
    <MuiChip label={label} />
  </div>
);

export default Chip;
