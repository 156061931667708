/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Document {
    // @ts-ignore
    startViewTransition?: (callback: () => void) => void;
  }
}

const useViewTransition = () => {
  const startViewTransition = (callback: (...params: any[]) => void) => {
    if (document.startViewTransition) {
      return document.startViewTransition(() => {
        callback();
      });
    }
    callback();
    return undefined;
  };

  return { startViewTransition };
};

export default useViewTransition;
