import LoadSpinner from 'components/atoms/LoadSpinner/LoadSpinner';

import './PageLoading.scss';

const PageLoading = () => {
  return (
    <div className="page-loading__wrapper">
      <LoadSpinner />
    </div>
  );
};

export default PageLoading;
