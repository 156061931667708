import React, { useEffect, useState } from 'react';
import './Headline.scss';
import { ReactComponent as ThumbUpSvg } from 'assets/icons/thumb-up.svg';
import { ReactComponent as ThumbDownSvg } from 'assets/icons/thumb-down.svg';
import useCompanies from 'hooks/useCompanies';
import useUser from 'hooks/useUser';
import { Popover, Skeleton } from '@mui/material';
import { ReactComponent as CloseSvg } from 'assets/icons/close.svg';
import { Headline as HeadlineProps } from 'api/company/company.model';
import useSkeletonLoader from 'hooks/useSkeletonLoader';

const Headline: React.FC<HeadlineProps> = ({
  id,
  companyProfileId,
  description,
  thumbnail,
  title,
  date,
  url,
  source,
  sourcerFeedback,
}) => {
  const { submitHeadlineFeedback } = useCompanies();
  const { user } = useUser();
  const [likeActive, setLikeActive] = useState(false);
  const [dislikeActive, setDislikeActive] = useState(false);
  const thumbsDownClassName = dislikeActive ? 'thumbs-down-active' : 'thumbs-down';
  const thumbsUpClassName = likeActive ? 'thumbs-up-active' : 'thumbs-up';
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [comment, setComment] = useState('');
  const { loading } = useSkeletonLoader();

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const feedback = sourcerFeedback.find((f) => f.sourcerId === user.user.id);
    setLikeActive(feedback?.liked ?? false);
    setDislikeActive(feedback?.disliked ?? false);
  }, [sourcerFeedback, user.user.id]);

  const handleLikeClick = () => {
    if (!likeActive) {
      setLikeActive(true);
      setDislikeActive(false);
      submitHeadlineFeedback(id, user.user.id, companyProfileId, 'Like');
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDislikeActive(false);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const handleSubmitDislike = () => {
    handleClose();
    submitHeadlineFeedback(id, user.user.id, companyProfileId, 'Dislike', comment);
    setLikeActive(false);
    setDislikeActive(true);
    setComment('');
  };

  useEffect(() => {
    const handleScroll = () => {
      handleClose();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <article className="headline" data-cy={`headline-item-${id}`}>
      {loading ? (
        <Skeleton variant="rounded" width={56} height={56} />
      ) : (
        <a href={url} target="_blank" rel="noreferrer">
          <div className="headline__image">
            <img src={thumbnail} alt="thumbnail" />
          </div>
        </a>
      )}

      <div className="headline__content">
        {loading ? (
          <Skeleton variant="rounded" width="20%" height={24} sx={{ bgcolor: 'grey.400' }} />
        ) : (
          <a href={url} target="_blank" rel="noreferrer">
            <span className="headline__info">
              {source} · {date}
            </span>
          </a>
        )}

        {loading ? (
          <>
            <Skeleton variant="rounded" width="80%" height={24} sx={{ bgcolor: 'grey.200' }} />
            <Skeleton variant="rounded" width="60%" height={22} sx={{ bgcolor: 'grey.200' }} />
          </>
        ) : (
          <a href={url} target="_blank" rel="noreferrer">
            <h3 className="headline__title">{title}</h3>
          </a>
        )}

        {loading ? (
          <>
            <Skeleton
              variant="rounded"
              width="70%"
              height={14}
              style={{ marginTop: '8px' }}
              sx={{ bgcolor: 'grey.100' }}
            />
            <Skeleton variant="rounded" width="70%" height={14} sx={{ bgcolor: 'grey.100' }} />
            <Skeleton variant="rounded" width="65%" height={14} sx={{ bgcolor: 'grey.100' }} />
          </>
        ) : (
          <p className="headline__paragraph">{description}</p>
        )}

        <div className="headline__feedback-buttons">
          {loading ? (
            <Skeleton variant="rounded" width={24} height={24} />
          ) : (
            <button
              type="button"
              onClick={handleClick}
              className={thumbsDownClassName}
              aria-label="Dislike"
              data-cy="thumbs-down-btn"
            >
              <ThumbDownSvg className="headline__feedback-thumb-down" />
            </button>
          )}

          {loading ? (
            <Skeleton variant="rounded" width={24} height={24} />
          ) : (
            <button
              type="button"
              onClick={handleLikeClick}
              className={thumbsUpClassName}
              data-cy="thumbs-up-btn"
            >
              <ThumbUpSvg className="headline__feedback-thumb-up" />
            </button>
          )}
        </div>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{ marginLeft: '10rem' }}
      >
        <div className="headline-modal" data-cy="headline-feedback-modal">
          <button className="headline-modal__close" type="button" aria-label="close">
            <CloseSvg onClick={handleClose} />
          </button>
          <h2>What could we improve?</h2>
          <textarea
            cols={30}
            rows={10}
            placeholder="Text here"
            value={comment}
            onChange={handleCommentChange}
            data-cy="headline-feedback-textarea"
          />
          <div className="headline-modal__actions">
            <button type="button" onClick={handleClose}>
              Cancel
            </button>
            <button
              onClick={handleSubmitDislike}
              type="button"
              className="headline-modal__submit"
              data-cy="headline-feedback-modal-submit"
            >
              Submit
            </button>
          </div>
        </div>
      </Popover>
    </article>
  );
};

export default Headline;
