import React from 'react';
import './ProspectOrigin.scss';
import { Skeleton } from '@mui/material';
import WebsiteIcon from 'components/atoms/WebsiteIcon/WebsiteIcon';
import { WEBSITES } from 'utils/constants';

interface AffinityListProps {
  prospectOrigin: string;
  loading: boolean;
}

const ProspectOrigin: React.FC<AffinityListProps> = ({ prospectOrigin, loading }) => {
  const getIcon = () => {
    if (loading) {
      return <Skeleton variant="circular" width={40} height={40} />;
    }

    switch (prospectOrigin) {
      case 'Harmonic':
        return <WebsiteIcon website={WEBSITES.HARMONIC} />;
      case 'Affinity':
        return <WebsiteIcon website={WEBSITES.AFFINITY} />;
      case 'Intern':
        return <WebsiteIcon website="" />;
      default:
        return <WebsiteIcon website="" />;
    }
  };

  return (
    <div className="prospect-origin">
      <div className="prospect-origin__icon">{getIcon()}</div>
      <div className="prospect-origin__info-container">
        {loading ? (
          <Skeleton
            variant="rounded"
            width={120}
            height={30}
            sx={{ bgcolor: 'grey.300', marginBottom: '8px' }}
          />
        ) : (
          <h3 className="prospect-origin__title">Prospect origin</h3>
        )}

        {loading ? (
          <Skeleton variant="rounded" width={120} height={20} sx={{ bgcolor: 'grey.200' }} />
        ) : (
          <p className="prospect-origin__origin">{prospectOrigin}</p>
        )}
      </div>
    </div>
  );
};

export default ProspectOrigin;
