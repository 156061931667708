import React from 'react';
import { ReactComponent as ToastDelete } from 'assets/icons/toast-delete.svg';
import FeedbackToast from 'components/atoms/FeedbackToast/FeedbackToast';

interface DangerFeedbackToastProps {
  hideToast: () => void;
  hiding: boolean;
}

const DangerMessage = () => <p>This company has been dismissed.</p>;

const DangerFeedbackToast: React.FC<DangerFeedbackToastProps> = ({ hideToast, hiding }) => {
  return (
    <FeedbackToast
      message={<DangerMessage />}
      icon={<ToastDelete />}
      primaryColor="#ff3e4c"
      secondaryColor="#fff2f3"
      onClose={hideToast}
      hiding={hiding}
    />
  );
};

export default DangerFeedbackToast;
