import Card from 'components/atoms/Card/Card';
import { useEffect, useState } from 'react';
import { ReactComponent as AnthosSvg } from 'assets/icons/anthos-logo-black.svg';
import html from './instagram';
import './InstagramEmbed.scss';

interface InstagramCardProps {
  profile: string;
  name: string;
  loading: boolean;
}

const InstagramCard = ({ profile, name, loading }: InstagramCardProps) => {
  const [finalHTML, setFinalHTML] = useState('');

  useEffect(() => {
    const replacedHTML = html.replaceAll('{PROFILE}', profile);
    setFinalHTML(replacedHTML.replace('{PROFILE_NAME}', name));

    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  }, [profile, name]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://www.instagram.com/embed.js';
    script.id = 'instagram-embed';
    script.async = true;

    document.body.appendChild(script);
  }, [finalHTML]);

  if (!profile) {
    return (
      <Card title="Instagram" loading={loading}>
        <div className="logo-container">
          <AnthosSvg className="anthos-logo" />
        </div>
        <p className="message">No Instagram profile available for {name}.</p>
      </Card>
    );
  }

  return (
    <Card title="Instagram" loading={loading}>
      <div className="instagram-embed" dangerouslySetInnerHTML={{ __html: finalHTML }} />
    </Card>
  );
};

export default InstagramCard;
