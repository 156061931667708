import React, { useEffect, useState } from 'react';
import Card from 'components/atoms/Card/Card';
import Chart from 'components/atoms/Chart/Chart';
import { chartConfig } from 'utils/constants';
import { DepartmentCount } from 'api/company/company.model';
import { ApexOptions } from 'apexcharts';

interface HeadcountCardProps {
  dates: string[];
  headcounts: DepartmentCount[];
  loading: boolean;
}

const HeadcountCard: React.FC<HeadcountCardProps> = ({ dates, headcounts, loading }) => {
  const [options, setOptions] = useState<ApexOptions>(chartConfig);
  const [series, setSeries] = useState<{ name: string; data: number[]; strokeWith: number }[]>([]);

  useEffect(() => {
    if (options.xaxis) {
      options.xaxis.categories = dates;
    }

    setOptions(options);

    setSeries(
      headcounts?.map((headcount) => ({
        name: headcount.area,
        data: headcount.employees,
        strokeWith: 3,
      })),
    );
  }, [dates, headcounts]);

  return (
    <Card title="Headcount Growth" loading={loading}>
      {options.xaxis?.categories.length > 0 && <Chart options={options} series={series} />}
    </Card>
  );
};

export default HeadcountCard;
