import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import PageLoading from 'components/molecules/PageLoading/PageLoading';

import paths from 'routes/paths';
import Layout from 'components/atoms/Layout/Layout';
import CompanyProfilePage from 'pages/CompanyProfilePage/CompanyProfilePage';
import NoMoreCompanies from 'pages/NoMoreCompanies/NoMoreCompanies';
import Redirect from '../components/Redirect/Redirect';

const PageNotFound = lazy(() => import('pages/page-not-found/PageNotFound'));
const Login = lazy(() => import('pages/LoginPage/LoginPage'));

interface Routes {
  path: string;
  element: React.ReactNode;
}

const getRouteElement = (Component: React.ElementType, checksAuth = false) => {
  if (!checksAuth || (checksAuth && sessionStorage.getItem('token'))) {
    return (
      <Suspense fallback={<PageLoading />}>
        <Layout>
          <Component />
        </Layout>
      </Suspense>
    );
  }
  return <Redirect url={paths.LOGIN} />;
};

const routes: Routes[] = [
  {
    path: paths.LOGIN,
    element: getRouteElement(Login),
  },
  {
    path: paths.NOT_FOUND,
    element: getRouteElement(PageNotFound),
  },
  {
    path: paths.COMPANY_PROFILE,
    element: getRouteElement(CompanyProfilePage, false),
  },
  {
    path: paths.NO_MORE_COMPANIES,
    element: getRouteElement(NoMoreCompanies),
  },
];

export default createBrowserRouter(routes);
