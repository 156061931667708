import React from 'react';
import Card from 'components/atoms/Card/Card';
import Note from 'components/atoms/Note/Note';
import { Note as INote } from 'api/company/company.model';
import { ReactComponent as AnthosSvg } from 'assets/icons/anthos-logo-black.svg';
import './AffinityNotesCard.scss';

interface AffinityNotesCardProps {
  notes: INote[];
  loading: boolean;
}

const parseDate = (dateString: string): Date => {
  const [day, month, year] = dateString.split('/').map(Number);
  return new Date(year, month - 1, day);
};

const AffinityNotesCard: React.FC<AffinityNotesCardProps> = ({ notes, loading }) => {
  const sortedNotes = [...notes].sort(
    (a, b) => parseDate(b.createdAt).getTime() - parseDate(a.createdAt).getTime(),
  );

  return (
    <Card title="Affinity Notes" loading={loading}>
      <div className="affinity-notest__container">
        {sortedNotes && sortedNotes.length > 0 ? (
          sortedNotes.map((note) => (
            <Note
              key={note.id}
              creator={note.author}
              content={note.content}
              date={note.createdAt}
            />
          ))
        ) : (
          <div className="affinity-notest__no-notes">
            <AnthosSvg className="affinity-notest__anthos" />
            <h2>No notes available.</h2>
          </div>
        )}
      </div>
    </Card>
  );
};

export default AffinityNotesCard;
