import React from 'react';
import { Skeleton } from '@mui/material';
import './Card.scss';

interface CardProps {
  children: React.ReactNode;
  title: string;
  loading: boolean;
}

const Card = ({ children, title, loading }: CardProps) => {
  return (
    <div className="card">
      {loading ? (
        <Skeleton
          variant="rounded"
          width="40%"
          height={25}
          animation="wave"
          sx={{ marginBottom: 1 }}
        />
      ) : (
        <h2 className="card__title">{title}</h2>
      )}

      {loading ? (
        <Skeleton
          variant="rounded"
          width="100%"
          height={180}
          animation="wave"
          sx={{ bgcolor: 'grey.300' }}
        />
      ) : (
        <div className="card__content">{children}</div>
      )}
    </div>
  );
};

export default Card;
