import { useState, useCallback } from 'react';
import { ToastType } from 'utils/constants';

const useFeedbackToast = () => {
  // Agregamos la propiedad `hiding` al estado para manejar la animación de salida.
  const [toast, setToast] = useState({ type: ToastType.NONE, show: false, hiding: false });

  const triggerToast = useCallback((type: ToastType) => {
    // Mostrar el toast.
    setToast({ type, show: true, hiding: false });

    // Temporizador para empezar la animación de salida.
    const timeoutId = setTimeout(() => {
      // Iniciar la animación de salida.
      setToast((prevToast) => ({ ...prevToast, hiding: true }));

      // Temporizador para ocultar el toast después de que la animación de salida se complete.
      const hideTimeoutId = setTimeout(() => {
        // Ocultar el toast.
        setToast({ type: ToastType.NONE, show: false, hiding: false });
      }, 1000); // Duración de la animación de salida.

      // Limpiar el temporizador de ocultar si el componente se desmonta.
      return () => clearTimeout(hideTimeoutId);
    }, 3500); // Duración que el toast permanece visible antes de empezar la animación de salida.

    // Limpiar el temporizador de inicio de la animación de salida si el componente se desmonta.
    return () => clearTimeout(timeoutId);
  }, []);

  const hideToast = useCallback(() => {
    // Ocultar el toast inmediatamente sin la animación de salida.
    // Esto podría ser útil para situaciones como un botón de cerrar en el toast.
    setToast({ type: ToastType.NONE, show: false, hiding: false });
  }, []);

  return {
    toast,
    triggerToast,
    hideToast,
  };
};

export default useFeedbackToast;
