import { useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import { ReactComponent as UndoSvg } from 'assets/icons/system-uicons-undo.svg';
import useCompanies from 'hooks/useCompanies';
import { Tooltip } from '@mui/material';
import './PreviousCompanyButton.scss';

const PreviousCompanyButton = () => {
  const { company, companiesAssignments, companiesMap, previousCompany, undoReview } =
    useCompanies();
  const previousCompanyNameRef = useRef<string | null>(null);

  const getPreviousCompanyName = () => {
    if (company && companiesMap) {
      const currentCompanyId = company.id?.toString();
      const currentIndex = companiesAssignments.findIndex(
        (assignment) => assignment.companyAffinityId === currentCompanyId,
      );

      if (currentIndex > 0) {
        const previousAssignment = companiesAssignments[currentIndex - 1];
        const previousCompanyId = previousAssignment.companyAffinityId;
        const prevCompany = companiesMap[previousCompanyId];
        return prevCompany?.name;
      }
    }
    return null;
  };

  useEffect(() => {
    previousCompanyNameRef.current = getPreviousCompanyName();
  }, [company]);

  const handleUndo = () => {
    const currentIndex = companiesAssignments.findIndex(
      (assignment) => assignment.companyAffinityId === company.id?.toString(),
    );

    if (currentIndex > 0) {
      const previousAssignment = companiesAssignments[currentIndex - 1];
      const previousCompanyId = previousAssignment.companyAffinityId;
      const sourcerId = previousAssignment.sourcerAffinityId;

      undoReview(sourcerId, previousCompanyId);
      previousCompany();

      console.log('Undo review and navigation triggered for company: ', previousCompanyId);
    }
  };

  return (
    <Tooltip title={previousCompanyNameRef.current || ''}>
      <Button onClick={handleUndo} className="undo-button" data-cy="undo-button">
        <UndoSvg />
      </Button>
    </Tooltip>
  );
};

export default PreviousCompanyButton;
