import './StickyNavbar.scss';
import CircleButton from 'components/atoms/CircleButton/CircleButton';
import { ButtonAction, ButtonType } from 'utils/constants';
import dangerMobile from 'assets/icons/danger-mobile.svg';
import reachMobile from 'assets/icons/reach-mobile.svg';
import skipMobile from 'assets/icons/skip-mobile.svg';
import trackMobile from 'assets/icons/track-mobile.svg';
import useCompanies from 'hooks/useCompanies';

interface StickyNavbarProps {
  toggleDialogs: (buttonAction: string) => void;
}

const StickyNavbar = ({ toggleDialogs }: StickyNavbarProps) => {
  const { nextCompany } = useCompanies();

  return (
    <div className="sticky-navbar">
      <div className="sticky-navbar__content">
        <CircleButton
          label="Don't Pursue"
          size="large"
          onClick={() => toggleDialogs(ButtonAction.DontPursue)}
          type={ButtonType.Danger}
          icon={<img src={dangerMobile} alt="Icon" />}
        />
      </div>

      <div className="sticky-navbar__content--top">
        <CircleButton
          label="Track"
          size="small"
          onClick={() => nextCompany()}
          type={ButtonType.Info}
          icon={<img src={trackMobile} alt="Icon" />}
        />
        <CircleButton
          label="Skip"
          size="small"
          onClick={() => toggleDialogs(ButtonAction.Skip)}
          type={ButtonType.Disabled}
          icon={<img src={skipMobile} alt="Icon" />}
        />
      </div>

      <div className="sticky-navbar__content">
        <CircleButton
          label="Reach Out"
          size="large"
          onClick={() => nextCompany()}
          type={ButtonType.Success}
          icon={<img src={reachMobile} alt="Icon" />}
        />
      </div>
    </div>
  );
};

export default StickyNavbar;
