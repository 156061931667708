import { Info } from 'lucide-react';

import './Alert.scss';

interface AlertProps {
  title: string;
}

const Alert = ({ title }: AlertProps) => (
  <div role="alert" className="alert">
    <Info className="alert__icon" />
    <p className="alert__text">{title}</p>
  </div>
);

export default Alert;
