import CircularProgress from '@mui/material/CircularProgress';

import anthosLogo from 'assets/icons/anthos-logo-black.svg';

import pageStyles from './LoadSpinner.module.scss';

const LoadSpinner = () => {
  return (
    <div className={pageStyles.loadSpinner__wrapper}>
      <img src={anthosLogo} alt="anthos logo" className={pageStyles.loadSpinner__logo} />
      <CircularProgress className={pageStyles.loadSpinner__spinner} />
    </div>
  );
};
export default LoadSpinner;
