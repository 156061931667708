import { FC } from 'react';
import Chip from 'components/atoms/Chip/Chip';

import './InvestorsList.scss';
import Alert from 'components/atoms/Alert/Alert';

interface InvestorsListProps {
  investors: string[];
}

const InvestorsList: FC<InvestorsListProps> = ({ investors }) => (
  <div className="investors">
    <div className="investors__header">
      <h2 className="investors__title">Investors</h2>
    </div>
    <div className="investors__content">
      {investors.length > 0 ? (
        investors.map((investor) => <Chip key={investor} label={investor} />)
      ) : (
        <Alert title="No investors data available." />
      )}
    </div>
  </div>
);

export default InvestorsList;
