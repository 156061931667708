import { useEffect, useState } from 'react';
import { WEBSITES, WEBSITES_DATA, Social } from 'utils/constants';
import { Tooltip, Skeleton } from '@mui/material';
import useSkeletonLoader from 'hooks/useSkeletonLoader';

interface WebsiteIconProps {
  website: string;
  onClick?: () => void;
}

const WebsiteIcon = ({ website, onClick }: WebsiteIconProps) => {
  const [site, setSite] = useState<Social>(WEBSITES_DATA.website);
  const { loading } = useSkeletonLoader();

  useEffect(() => {
    const fetchDataWithDelay = async () => {
      Object.values(WEBSITES).forEach((social) => {
        if (website.includes(social)) {
          setSite(WEBSITES_DATA[social]);
        }
      });
    };

    fetchDataWithDelay();
  }, [website]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Tooltip title={site?.name}>
      {loading ? (
        <Skeleton variant="rounded" width={24} height={24} />
      ) : (
        <a
          href={website}
          target="_blank"
          className="company-profile-page__social-icon"
          rel="noreferrer"
          onClick={handleClick}
        >
          {site && site.icon && <site.icon />}
        </a>
      )}
    </Tooltip>
  );
};

export default WebsiteIcon;
