import { useState } from 'react';
import Dialog from 'components/atoms/Dialog/Dialog';
import useCompanies from 'hooks/useCompanies';
import { ButtonAction, SKIP_REASONS } from 'utils/constants';
import { Checkbox, FormControlLabel, Select, MenuItem } from '@mui/material';
import './ReasonsDialog.scss';
import { usePostHog } from 'posthog-js/react';
import { User } from 'api/user/user.model';
import useUser from 'hooks/useUser';

interface ReasonsDialogProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  reasons: Array<{ label: string; id: number }>;
  actionType: ButtonAction;
  availableUsers?: Array<User>;
  textAreaReason?: number;
  feedbackToastAction?: () => void;
}
const ReasonsDialog = ({
  setIsOpen,
  isOpen,
  reasons,
  actionType,
  availableUsers = [],
  textAreaReason,
  feedbackToastAction,
}: ReasonsDialogProps) => {
  const { nextCompany, submitCompanyEvent, company } = useCompanies();
  const { user } = useUser();
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const posthog = usePostHog();

  const toggleOption = (id: number) => {
    if (selectedOptions.includes(id)) {
      if (id === reasons[reasons.length - 1].id && !!notes) return;
      setSelectedOptions(selectedOptions.filter((option) => option !== id));
    } else {
      setSelectedOptions([...selectedOptions, id]);
    }
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
    if (selectedOptions.length === 0) {
      toggleOption(reasons[reasons.length - 1].id);
    }
  };

  return (
    <Dialog
      onPrimaryAction={() => {
        if (selectedOptions.length > 0) {
          const selectedOptionsLabels = reasons
            .filter((reason) => selectedOptions.includes(reason.id))
            .map((reason) => reason.label);
          submitCompanyEvent(
            actionType,
            company.snapshotId,
            selectedOptionsLabels,
            notes,
            company.listEntryId,
            selectedOptionsLabels.includes(SKIP_REASONS[0].label)
              ? selectedUser?.affinityId
              : undefined,
          );
          nextCompany();
          setIsOpen(false);
          posthog?.capture(actionType, {
            company_id: company.id,
            company_name: company.name,
          });
          if (feedbackToastAction) {
            feedbackToastAction();
          }
        }
      }}
      onSecondaryAction={() => setIsOpen(false)}
      isOpen={isOpen}
      title="Any reason?"
      primaryActionDisabled={selectedOptions.length === 0}
    >
      {reasons.map((reason) => (
        <FormControlLabel
          key={reason.label}
          data-cy={`reason-${reason.id}`}
          control={
            <Checkbox
              id={reason.id.toString()}
              data-cy={`checkbox-${reason.id}`}
              title={reason.label}
              checked={selectedOptions.indexOf(reason.id) !== -1}
              onChange={() => toggleOption(reason.id)}
            />
          }
          label={reason.label}
        />
      ))}

      {actionType && textAreaReason && selectedOptions.includes(textAreaReason) && (
        <textarea
          value={notes}
          onChange={handleNotesChange}
          name="Notes"
          cols={30}
          rows={5}
          className="dialog__area"
          data-cy="dialog-text-area"
        />
      )}

      {availableUsers.length > 0 && selectedOptions.includes(1) && (
        <>
          <h2 className="dialog__label">Reassign review to: (optional)</h2>
          <Select
            className="dialog__area__select"
            value={selectedUser ? selectedUser.id : ''}
            onChange={(event) => {
              const selectedUserId = event.target.value as string;
              const selectedUserObject =
                availableUsers.find((usr) => usr.id === selectedUserId) || null;
              setSelectedUser(selectedUserObject);
            }}
            data-cy="dialog-reassign-select"
          >
            {availableUsers
              .filter((usr) => usr.id !== user.user.id) // Filtrar el usuario actual
              .map((usr) => (
                <MenuItem
                  key={usr.id}
                  value={usr.id}
                  className="dialog__area__select__option"
                  data-cy={`reassign-user-${usr.id}`}
                >
                  {usr.name}
                </MenuItem>
              ))}
          </Select>
        </>
      )}
    </Dialog>
  );
};

export default ReasonsDialog;
