import { Typography, useTheme } from '@mui/material';
import { ReactComponent as ChevronRight } from 'assets/icons/prime_chevron-up.svg';
import ReactMarkdown from 'react-markdown';
import './Note.scss';

interface NoteProps {
  creator: string;
  content: string;
  date: string;
}

const Note = ({ creator, content, date }: NoteProps) => {
  const theme = useTheme();
  return (
    <div className="affinity-note">
      <div className="affinity-note__header">
        <ChevronRight />
        <Typography
          component="h3"
          fontWeight={600}
          fontSize={14}
          color={theme.palette.textSecondary}
          className="affinity-note__creator"
        >
          Note by {creator} - {date}
        </Typography>
      </div>
      <ReactMarkdown className="markdown-styles">{content}</ReactMarkdown>
    </div>
  );
};

export default Note;
