import { useEffect, useState } from 'react';
import './Header.scss';
import Button from 'components/atoms/Button/Button';
import dontPursue from 'assets/icons/dontPursue.svg';
import reachOut from 'assets/icons/reachOut.svg';
import skip from 'assets/icons/skip.svg';
import track from 'assets/icons/track.svg';
import useCompanies from 'hooks/useCompanies';
import Container from 'components/molecules/Container/Container';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksThreeIcon from '@mui/icons-material/Looks3';
import LooksFourIcon from '@mui/icons-material/Looks4';
import { ButtonAction } from 'utils/constants';
import useUser from 'hooks/useUser';
import { usePostHog } from 'posthog-js/react';
import PreviousCompanyButton from 'components/atoms/UndoToast/PreviousCompanyButton';
import { ReactComponent as DivisorSvg } from 'assets/icons/divisor.svg';

interface HeaderProps {
  isModalOpen: boolean;
  toggleDialogs: (actionType: string) => void;
  onReachOut: () => void;
}

const Header = ({ toggleDialogs, isModalOpen, onReachOut }: HeaderProps) => {
  const { nextCompany, company, companiesAssignments, submitCompanyEvent } = useCompanies();
  const [reachOutButtonConfirmation, setReachOutButtonConfirmation] = useState(false);
  const [trackButtonConfirmation, setTrackButtonConfirmation] = useState(false);
  const [skipButtonConfirmation, setSkipButtonConfirmation] = useState(false);
  const [dontPursueButtonConfirmation, setDontPursueButtonConfirmation] = useState(false);
  const { user, fetchUserDataFromAPI } = useUser();
  const posthog = usePostHog();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isModalOpen) return;
      switch (event.keyCode) {
        case 49:
          setReachOutButtonConfirmation(true);
          setTimeout(() => {
            setReachOutButtonConfirmation(false);
          }, 10);
          nextCompany();
          posthog?.capture('submit_review', {
            action_type: ButtonAction.Reach,
            company_id: company.id,
            company_name: company.name,
          });
          onReachOut();
          break;
        case 50:
          setTrackButtonConfirmation(true);
          setTimeout(() => {
            setTrackButtonConfirmation(false);
          }, 10);
          nextCompany();
          posthog?.capture('submit_review', {
            action_type: ButtonAction.Track,
            company_id: company.id,
            company_name: company.name,
          });
          break;
        case 51:
          setSkipButtonConfirmation(true);
          setTimeout(() => {
            setSkipButtonConfirmation(false);
          }, 10);
          nextCompany();
          posthog?.capture('skiped', {
            company_id: company.id,
            company_name: company.name,
          });
          break;
        case 52:
          setDontPursueButtonConfirmation(true);
          toggleDialogs(ButtonAction.DontPursue);
          break;

        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [nextCompany, toggleDialogs, isModalOpen]);

  useEffect(() => {
    fetchUserDataFromAPI();
  }, [fetchUserDataFromAPI]);

  return (
    <header className="header">
      <Container>
        <div className="header__container" data-cy="header-container">
          <h2 className="header__user">
            Welcome, <b>{user.user.name}</b>
          </h2>
          <div className="header__right-section">
            <div className="header__buttons" data-cy="header-buttons">
              <Button
                label="reach out"
                customClass="button-success"
                testClass="reach-out-button"
                icon={<img src={reachOut} alt="icon" />}
                hasConfirmAction
                onClick={() => {
                  submitCompanyEvent(
                    ButtonAction.Reach,
                    company.snapshotId,
                    [],
                    '',
                    company.listEntryId,
                  );
                  nextCompany();
                  posthog?.capture('submit_review', {
                    action_type: ButtonAction.Reach,
                    company_id: company.id,
                    company_name: company.name,
                  });

                  onReachOut();
                }}
                iconPosition="left"
                triggerConfirmation={reachOutButtonConfirmation}
                numberIcon={<LooksOneIcon />}
              />
              <Button
                label="track"
                customClass="button-info"
                testClass="track-button"
                hasConfirmAction
                icon={<img src={track} alt="icon" />}
                onClick={() => {
                  submitCompanyEvent(
                    ButtonAction.Track,
                    company.snapshotId,
                    [],
                    '',
                    company.listEntryId,
                  );
                  nextCompany();
                  posthog?.capture('submit_review', {
                    action_type: ButtonAction.Track,
                    company_id: company.id,
                    company_name: company.name,
                  });
                }}
                iconPosition="left"
                triggerConfirmation={trackButtonConfirmation}
                numberIcon={<LooksTwoIcon />}
              />
              <Button
                label="skip"
                customClass="button-disabled"
                testClass="skip-button"
                hasConfirmAction
                icon={<img src={skip} alt="icon" />}
                onClick={() => {
                  toggleDialogs(ButtonAction.Skip);
                }}
                iconPosition="left"
                triggerConfirmation={skipButtonConfirmation}
                numberIcon={<LooksThreeIcon />}
              />
              <Button
                label="don't pursue"
                customClass="button-danger"
                testClass="dont-pursue-button"
                icon={<img src={dontPursue} alt="icon" />}
                onClick={() => {
                  toggleDialogs(ButtonAction.DontPursue);
                }}
                iconPosition="left"
                triggerConfirmation={dontPursueButtonConfirmation}
                numberIcon={<LooksFourIcon />}
              />
            </div>
            <DivisorSvg className="header__divisor" />
            <PreviousCompanyButton />
            <div className="header__reviews-container" data-cy="reviews-container">
              <span>Weekly Review</span>
              <span>
                {companiesAssignments.findIndex(
                  (assignment) => assignment.companyAffinityId === company.id?.toString(),
                ) + 1}
                /{companiesAssignments.length}
              </span>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
