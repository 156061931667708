import React, { ReactNode } from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import Button from 'components/atoms/Button/Button';
import CloseIcon from '@mui/icons-material/Close';
import './Dialog.scss';

interface DialogProps {
  isOpen: boolean;
  onClose?: () => void;
  title: string;
  children: ReactNode;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  secondaryLabel?: string;
  mainLabel?: string;
  primaryActionDisabled?: boolean;
}

const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  title,
  children,
  onPrimaryAction,
  onSecondaryAction,
  secondaryLabel = 'Cancel',
  mainLabel = 'Submit',
  primaryActionDisabled = false,
}) => {
  return (
    <MuiDialog open={isOpen} onClose={onClose} className="dialog">
      <div className="dialog__header">
        <DialogTitle className="dialog__title">{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onSecondaryAction}
          sx={{
            padding: '0',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="dialog__content" data-cy="dialog-content">
        {children}
      </DialogContent>
      <DialogActions className="dialog__actions" data-cy="dialog-actions">
        <Button
          label={secondaryLabel}
          size="small"
          onClick={onSecondaryAction}
          customClass="button-text"
        />
        <Button
          label={mainLabel}
          size="small"
          onClick={onPrimaryAction}
          disabled={primaryActionDisabled}
          customClass="button-submit"
          testClass="dialog-primary-action"
        />
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
