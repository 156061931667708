import { FC } from 'react';
import { Skeleton } from '@mui/material';
import classNames from 'classnames';

import './Info.scss';

interface InfoProps {
  label: string;
  icon: React.ReactNode;
  title: string;
  position: 'left' | 'right';
  loading: boolean;
}

const Info: FC<InfoProps> = ({ label, icon, title, position, loading }) => {
  const isIconOnLeft = position === 'left';

  return (
    <div className={classNames('info', { loading })} data-cy="info-item">
      {loading ? (
        <>
          <Skeleton variant="rounded" width="85%" height={24} sx={{ bgcolor: 'grey.300' }} />
          <Skeleton variant="rounded" width="50%" height={24} sx={{ bgcolor: 'grey.200' }} />
        </>
      ) : (
        <>
          {isIconOnLeft && <div className="info__icon">{icon}</div>}
          <div className="info__content">
            <h3 className="info__title">{title}</h3>
            <p className="info__label">
              {label &&
                label.split('\n').map((str, index, array) =>
                  array.length - 1 === index ? (
                    str
                  ) : (
                    <>
                      {str}
                      <br />
                    </>
                  ),
                )}
            </p>
          </div>
          {!isIconOnLeft && <div className="info__icon">{icon}</div>}
        </>
      )}
    </div>
  );
};

export default Info;
