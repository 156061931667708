import React, { ReactElement } from 'react';
import { ReactComponent as CloseSvg } from 'assets/icons/close.svg';
import classNames from 'classnames';

import './FeedbackToast.scss';

interface FeedbackToastProps {
  icon: React.ReactNode;
  message: ReactElement<any, any>;
  primaryColor: string;
  secondaryColor: string;
  onClose: () => void;
  hiding: boolean;
}

const FeedbackToast: React.FC<FeedbackToastProps> = ({
  icon,
  message,
  primaryColor,
  secondaryColor,
  onClose,
  hiding,
}) => {
  const toastStyle = {
    backgroundColor: secondaryColor,
    borderBottom: `1px solid ${primaryColor}`,
  };

  return (
    <div
      className={classNames('feedback-toast', { 'feedback-toast--hiding': hiding })}
      style={toastStyle}
    >
      <div className="feedback-toast__icon">{icon}</div>
      <div className="feedback-toast__message">{message}</div>
      <CloseSvg className="feedback-toast__close" onClick={onClose} />
    </div>
  );
};

export default FeedbackToast;
