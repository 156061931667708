import { useMemo, useState } from 'react';

import './CollapsibleDescription.scss';
import useSkeletonLoader from 'hooks/useSkeletonLoader';
import { Skeleton } from '@mui/material';

const TRUNCATED_DESCRIPTION_MAX_WORDS = 55;

interface CollapsibleDescriptionProps {
  description: string;
  callback?: () => void;
}

function CollapsibleDescription({ description, callback }: CollapsibleDescriptionProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { loading } = useSkeletonLoader();

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
    if (callback) {
      callback();
    }
  };

  const buttonLabel = isExpanded ? 'Show less' : 'Show more';

  const descriptionWordsLength = useMemo(() => {
    return description?.split(/(\s+)/).filter((x) => x.trim().length > 0).length;
  }, [description]);

  const truncatedDescription = useMemo(() => {
    if (!isExpanded && descriptionWordsLength > TRUNCATED_DESCRIPTION_MAX_WORDS) {
      return `${description?.split(' ').splice(0, TRUNCATED_DESCRIPTION_MAX_WORDS).join(' ')}...`;
    }

    return description;
  }, [isExpanded, descriptionWordsLength, description]);

  return (
    <div className="collapsible-description">
      {loading ? (
        <Skeleton
          variant="rounded"
          width="55%"
          height={24}
          sx={{ bgcolor: 'grey.400', marginBottom: 1 }}
        />
      ) : (
        <h2 className="collapsible-description__title">Description</h2>
      )}

      {loading ? (
        <>
          <Skeleton
            variant="rounded"
            width="90%"
            height={22}
            sx={{ bgcolor: 'grey.200', marginBottom: 1 }}
          />
          <Skeleton
            variant="rounded"
            width="30%"
            height={22}
            sx={{ bgcolor: 'grey.200', marginBottom: 1 }}
          />
        </>
      ) : (
        <p className="collapsible-description__paragraph">{truncatedDescription}</p>
      )}

      {loading || descriptionWordsLength <= TRUNCATED_DESCRIPTION_MAX_WORDS ? null : (
        <button
          className="collapsible-description__button"
          onClick={toggleDescription}
          type="button"
        >
          {buttonLabel}
        </button>
      )}
    </div>
  );
}

export default CollapsibleDescription;
