import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import companyApi from 'api/company/company.api';
import { Company, CompanyAssignment } from 'api/company/company.model';
import paths from 'routes/paths';
import { errorMessage } from 'utils/constants';
import { setNavigationState } from './navigationSlice';
import { FundingSeries } from '../../api/company/company.model';

export interface CompaniesState {
  selectedCompanyId: number;
  companiesMap: { [key: string]: Company };
  companiesAssignments: CompanyAssignment[];
  isLoading: boolean;
  error: string | null;
  noMoreDeals: boolean;
  selectedCompanyListEntryId: string;
}

const INITIAL_STATE = {
  selectedCompanyId: 0,
  companiesAssignments: [],
  companiesMap: {},
  isLoading: false,
  error: null,
  noMoreDeals: false,
  selectedCompanyListEntryId: '',
} as CompaniesState;

export const getOneCompany = createAsyncThunk('company/getOneCompany', companyApi.getOneCompany);
export const undoReview = createAsyncThunk('company/undoReview', companyApi.undoReview);
export const postEvent = createAsyncThunk('company/postEvent', companyApi.submitEvent);
export const postHeadlineFeedback = createAsyncThunk(
  'company/postHeadlineFeedback',
  companyApi.submitHeadlineFeedback,
);

export const getFundraisingData = createAsyncThunk(
  'company/getFundraisingData',
  companyApi.getFundraisingData,
);
export const getAllCompanies = createAsyncThunk(
  'company/getAllCompanies',
  async (_arg, thunkAPI) => {
    const response: CompanyAssignment[] = await companyApi.getAllCompanies();

    // if no companies get returned, navigate to the no more companies page
    if (response.filter((assignment) => !assignment.hasReview).length === 0) {
      thunkAPI.dispatch(
        setNavigationState({ shouldNavigate: true, targetRoute: paths.NO_MORE_COMPANIES }),
      );
    } else {
      const firstAssignmentWithoutReview = response.find((assignment) => !assignment.hasReview);

      if (!firstAssignmentWithoutReview) return response;

      thunkAPI.dispatch(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setSelectedCompany(firstAssignmentWithoutReview),
      );
      thunkAPI.dispatch(getOneCompany(firstAssignmentWithoutReview?.companyAffinityId)).then(() => {
        setTimeout(() => {
          response.slice(1).forEach((assignment) => {
            thunkAPI.dispatch(getOneCompany(assignment.companyAffinityId));
          });
        }, 2000);
      });
    }

    return response;
  },
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedCompany: (state, action: PayloadAction<CompanyAssignment>) => {
      return {
        ...state,
        noMoreDeals: false,
        selectedCompanyId: parseInt(action.payload.companyAffinityId, 10),
        selectedCompanyListEntryId: action.payload.listEntryId,
      };
    },
  },
  extraReducers(builder) {
    // Handle getOneCompany
    builder.addCase(getOneCompany.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });

    builder.addCase(getOneCompany.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const updatedCompany = {
        ...action.payload,
      };
      return {
        ...state,
        isLoading: false,
        companiesMap: {
          ...state.companiesMap,
          [updatedCompany.id.toString()]: updatedCompany,
        },
      };
    });

    builder.addCase(getOneCompany.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        companiesAssignments: state.companiesAssignments.filter(
          (assignment) => assignment.companyAffinityId !== state.selectedCompanyId.toString(),
        ),
        error: action.error.message || errorMessage,
      };
    });

    // Handle getAllCompanies
    builder.addCase(getAllCompanies.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });

    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      return {
        ...state,
        companiesAssignments: action.payload,
        isLoading: false,
      };
    });

    builder.addCase(getAllCompanies.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || errorMessage,
      };
    });

    // Handle postEvent
    builder.addCase(postEvent.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });

    builder.addCase(postEvent.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    });

    builder.addCase(postEvent.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || errorMessage,
      };
    });

    // Handle postHeadlineFeedback

    builder.addCase(postHeadlineFeedback.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });

    builder.addCase(postHeadlineFeedback.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    });

    builder.addCase(postHeadlineFeedback.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || errorMessage,
      };
    });

    // Handle getFundraisingData
    builder.addCase(getFundraisingData.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });

    builder.addCase(getFundraisingData.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        companiesMap: {
          ...state.companiesMap,
          [state.selectedCompanyId.toString()]: {
            ...state.companiesMap[state.selectedCompanyId.toString()],
            funding: {
              ...state.companiesMap[state.selectedCompanyId.toString()].funding,
              history: action.payload.history
                .filter((deal: FundingSeries) => {
                  const companyFundingLast =
                    state.companiesMap[state.selectedCompanyId.toString()].funding.last;
                  return (
                    deal.date !== companyFundingLast.date &&
                    deal.amount !== companyFundingLast.amount &&
                    deal.round !== companyFundingLast.round
                  );
                })
                .sort((deal1: FundingSeries, deal2: FundingSeries) => {
                  return new Date(deal2.date).getTime() - new Date(deal1.date).getTime();
                }),
            },
          },
        },
        noMoreDeals: action.payload.stats.page === action.payload.stats.lastPage,
      };
    });

    builder.addCase(getFundraisingData.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || errorMessage,
      };
    });

    // Handle undoReview
    builder.addCase(undoReview.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || errorMessage,
      };
    });
  },
});

export const { setSelectedCompany } = companiesSlice.actions;

export default companiesSlice.reducer;
