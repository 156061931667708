import './NoMoreCompanies.scss';
import { ReactComponent as NoMoreCompaniesIcon } from 'assets/no-more-companies.svg';
import useUser from 'hooks/useUser';
import { useEffect } from 'react';

const NoMoreCompanies: React.FC = () => {
  const { user, fetchUserDataFromAPI } = useUser();

  useEffect(() => {
    fetchUserDataFromAPI();
  }, [fetchUserDataFromAPI]);

  return (
    <div className="no-more-companies">
      <NoMoreCompaniesIcon className="no-more-companies__image" />
      <h1 className="no-more-companies__title">
        Great news, {user.user.name || 'Default User'}! You&apos;re all caught up for the week.
      </h1>
      <p className="no-more-companies__text">
        We&apos;ll notify you as soon as new companies become available for evaluation.
      </p>
    </div>
  );
};

export default NoMoreCompanies;
