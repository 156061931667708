import { FC } from 'react';
import ReactApexCharts from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import './Chart.scss';
import { useMediaQuery } from '@mui/material';

interface HeadcountCardProps {
  options: ApexOptions;
  series: ApexOptions['series'];
}

const Chart: FC<HeadcountCardProps> = ({ options, series }) => {
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <div className="custom-chart-container">
      <ReactApexCharts
        options={options}
        series={series}
        type="area"
        height={isMobile ? 250 : 'auto'}
      />
    </div>
  );
};

export default Chart;
