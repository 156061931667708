import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userApi from 'api/user/user.api';
import { User } from 'api/user/user.model';
import { errorMessage } from 'utils/constants';

interface UserState {
  user: User;
  isLoading: boolean;
  error: string | null;
  availableUsers: Array<User>;
}

const INITIAL_STATE: UserState = {
  user: {
    id: '',
    name: '',
    email: '',
    affinityId: '',
    personalAffinityListId: '',
  },
  isLoading: false,
  error: null,
  availableUsers: [],
};

export const fetchUserData = createAsyncThunk('user/fetchUserData', userApi.getMe);
export const getAvailableUsers = createAsyncThunk(
  'company/getAvailableUsers',
  userApi.getAvailableUsers,
);

// const userSlice = createSlice({
//   name: 'user',
//   initialState: INITIAL_STATE,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(fetchUserData.pending, (state) => {
//       state.isLoading = true;
//       state.error = null;
//     });

//     builder.addCase(fetchUserData.fulfilled, (state, action) => {
//       state.user.id = action.payload.id;
//       state.user.name = action.payload.name;
//       state.user.email = action.payload.email;
//       state.isLoading = false;
//     });

//     builder.addCase(fetchUserData.rejected, (state, action) => {
//       state.isLoading = false;
//       state.error = action.error.message || 'An error occurred';
//     });
//   },
// });

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });

    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      return {
        ...state,
        user: {
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          affinityId: action.payload.affinityId,
          personalAffinityListId: action.payload.personalAffinityListId,
        },
        isLoading: false,
      };
    });

    builder.addCase(fetchUserData.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || 'An error occurred',
      };
    });

    // Handle getAvailableUsers
    builder.addCase(getAvailableUsers.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    });

    builder.addCase(getAvailableUsers.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        availableUsers: action.payload,
      };
    });

    builder.addCase(getAvailableUsers.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error.message || errorMessage,
      };
    });
  },
});

export default userSlice.reducer;
