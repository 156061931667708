import React from 'react';
import { Button as MuiButton } from '@mui/material';
import classNames from 'classnames';
import { ButtonType } from '../../../utils/constants';

import './CircleButton.scss';

interface CircleButtonProps {
  label: string;
  type: ButtonType;
  icon: React.ReactNode;
  size: 'small' | 'large';
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const CircleButton: React.FC<CircleButtonProps> = ({ label, type, icon, size, onClick }) => (
  <div className="circle-button">
    <MuiButton
      className={`circle-button__wrapper circle-button__wrapper--${type}`}
      disableElevation
      disableRipple
      onClick={onClick}
    >
      <span
        className={classNames(`circle-button__icon circle-button__icon--${type}`, {
          'circle-button__icon--small': size === 'small',
        })}
      >
        {icon}
      </span>
      <span className="circle-button__label">{label}</span>
    </MuiButton>
  </div>
);

export default CircleButton;
