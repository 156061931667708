import { FC, useRef } from 'react';
import './HeadlinesList.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Headline as HeadlineProps } from 'api/company/company.model';
import { compareHeadlinesByDate } from 'utils/utils';
import { ReactComponent as ChevronRightSvg } from 'assets/icons/right-chevron.svg';
import { ReactComponent as ChevronLeftSvg } from 'assets/icons/left-chevron.svg';
import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import Headline from '../../atoms/Headline/Headline';

interface HeadlinesListProps {
  headlines: HeadlineProps[] | null;
  loading: boolean;
}

const HeadlinesList: FC<HeadlinesListProps> = ({ headlines, loading }) => {
  const sortedHeadlineArray = headlines ? [...headlines].sort(compareHeadlinesByDate) : [];

  const settings = {
    dots: !loading,
    speed: 500,
    slidesToShow: headlines && headlines.length >= 3 ? 3 : 1,
    slidesToScroll: headlines && headlines.length >= 3 ? 3 : 1,
    infinite: !!headlines && headlines.length >= 3,
    arrows: false,
  };

  const sliderRef = useRef<Slider>(null);

  const goToNext = () => {
    sliderRef.current?.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current?.slickPrev();
  };

  if (!sortedHeadlineArray || sortedHeadlineArray.length === 0) {
    return (
      <div className="headlines-list">
        <div className="headlines-list__head">
          {loading ? (
            <Skeleton variant="rounded" width={140} height={24} />
          ) : (
            <h2 className="headlines-list__title">Headlines</h2>
          )}
        </div>
        <div className="headlines-list__body">
          {loading ? (
            <Skeleton variant="rounded" width={140} height={24} />
          ) : (
            <p className="headlines-list__message">No headlines available.</p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('headlines-list', { loading })}>
      <div className="headlines-list__head">
        {loading ? (
          <Skeleton variant="rounded" width={140} height={24} />
        ) : (
          <h2 className="headlines-list__title">Headlines</h2>
        )}
      </div>
      <div className="headlines-list__body" data-cy="headlines-list">
        <Slider ref={sliderRef} {...settings}>
          {sortedHeadlineArray.map((headline) => (
            <Headline key={headline.id} {...headline} />
          ))}
        </Slider>
        {!loading && sortedHeadlineArray.length > 3 && (
          <div className="controls">
            <button
              type="button"
              onClick={goToPrev}
              className="custom-prev-button"
              aria-label="Previous slide"
            >
              <ChevronLeftSvg />
            </button>
            <button
              type="button"
              onClick={goToNext}
              className="custom-next-button"
              aria-label="Next slide"
            >
              <ChevronRightSvg />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeadlinesList;
