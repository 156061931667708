import { FC } from 'react';
import { FundingSeries } from 'api/company/company.model';
import Card from 'components/atoms/Card/Card';
import FundraisingTable from 'components/molecules/FundingTable/FundingTable';
import InvestorsList from 'components/molecules/InvestorsList/InvestorsList';

import './FundraisingHistoryCard.scss';

interface FundraisingHistoryCardProps {
  fundraisingData: FundingSeries[];
  investors: string[];
  loading: boolean;
}

const FundraisingHistoryCard: FC<FundraisingHistoryCardProps> = ({
  fundraisingData,
  investors,
  loading,
}) => (
  <Card title="Fundraising History" loading={loading}>
    <FundraisingTable fundraisingData={fundraisingData} />
    <InvestorsList investors={investors} />
  </Card>
);

export default FundraisingHistoryCard;
