import React from 'react';
import './AffinityList.scss';
import { ReactComponent as ChevronRight } from 'assets/icons/prime_chevron-up.svg';
import { AffinityList as AffinityListModel } from 'api/company/company.model';
import { Skeleton } from '@mui/material';

interface AffinityListProps {
  affinityLists: AffinityListModel[];
  loading: boolean;
}

const AffinityList: React.FC<AffinityListProps> = ({ affinityLists, loading }) => {
  return (
    <div className="affinity-list">
      {loading ? (
        <Skeleton
          variant="rounded"
          width={120}
          height={30}
          sx={{ bgcolor: 'grey.300', marginBottom: '8px' }}
        />
      ) : (
        <h3 className="affinity-list__title">Affinity List</h3>
      )}

      {loading ? (
        <Skeleton variant="rounded" width={120} height={20} sx={{ bgcolor: 'grey.200' }} />
      ) : (
        <ul className="affinity-list__list">
          {affinityLists &&
            affinityLists.map((affinityList, index) => (
              <li key={index} className="affinity-list__item">
                <a
                  href={affinityList.url}
                  target="_blank"
                  className="affinity-list__link"
                  rel="noreferrer"
                >
                  {affinityList.name}
                  <ChevronRight />
                </a>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default AffinityList;
