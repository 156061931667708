import Info from 'components/atoms/Info/Info';
import { Company } from 'api/company/company.model';
import { calculateMonthsDifference } from 'utils/functions';
import { noRelationshipLeadLabel, notAvailableLabel } from 'utils/constants';
import LocationSvg from 'assets/icons/location.svg';
import CalendarSvg from 'assets/icons/calendar.svg';
import CalendarCheckSvg from 'assets/icons/calendarcheck.svg';
import GraphUpSvg from 'assets/icons/graphup.svg';
import ValuationSvg from 'assets/icons/valuation.svg';
import LeadSvg from 'assets/icons/lead.svg';

import './InfoSidebar.scss';

interface InfoSidebarProps {
  company: Company;
  isLoading?: boolean;
}

const InfoSidebar = ({ company, isLoading = false }: InfoSidebarProps) => {
  return (
    <div className="info-sidebar" data-cy="company-page-info-list">
      {company.location || company.yearFounded ? (
        <Info
          title="Geo | Date Founded"
          label={`${company.location || notAvailableLabel} | ${
            company.yearFounded || notAvailableLabel
          }`}
          icon={<img src={LocationSvg} alt="Geo and Date Founded" />}
          position="left"
          loading={isLoading}
        />
      ) : null}
      {/* It needs backend to work */}
      {/* <Info
                  label={
                    company.interactions.last.date +
                    '\n' +
                    company.interactions.last.type +
                    ' from ' +
                    company.interactions.last.user
                  }
                  title="Date last contacted"
                  icon={<img src={DoubleCheckSvg} />}
                  position="left"
                /> */}
      {company.funding?.last.date ? (
        <>
          <Info
            title="Months since Last Raise"
            label={calculateMonthsDifference(company.funding?.last.date, new Date()).toString()}
            icon={<img src={CalendarSvg} alt="Months since Last Raise" />}
            position="left"
            loading={isLoading}
          />
          <Info
            label={company.funding?.last.date}
            title="Date of Last Raise"
            icon={<img src={CalendarCheckSvg} alt="Date of Last Raise" />}
            position="left"
            loading={isLoading}
          />
        </>
      ) : null}
      {company.funding?.last.amount.amount && (
        <Info
          label={
            company.funding?.last.amount.amount
              ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: company.funding.last.amount.currency || 'USD',
                  minimumFractionDigits: 0,
                }).format(parseInt(company.funding.last.amount.amount, 10))
              : notAvailableLabel
          }
          title="Amount of last raise"
          icon={<img src={GraphUpSvg} alt="Amount of last raise" />}
          position="left"
          loading={isLoading}
        />
      )}
      {company.funding?.last.valuation.amount && (
        <Info
          label={
            company.funding?.last.valuation.amount
              ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: company.funding.last.valuation.currency || 'USD',
                  minimumFractionDigits: 0,
                }).format(parseInt(company.funding.last.valuation.amount, 10))
              : notAvailableLabel
          }
          title="Last post-money valuation"
          icon={<img src={ValuationSvg} alt="Last post-money valuation" />}
          position="left"
          loading={isLoading}
        />
      )}
      <Info
        label={
          company.relationshipLead ? company.relationshipLead.toString() : noRelationshipLeadLabel
        }
        title="Relationship Lead"
        icon={<img src={LeadSvg} alt="Relationship Lead" />}
        position="left"
        loading={isLoading}
      />
    </div>
  );
};

export default InfoSidebar;
