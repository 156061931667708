import React from 'react';
import './Layout.scss';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return <main className="layout">{children}</main>;
};

export default Layout;
