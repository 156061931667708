import './FundingTable.scss';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FundraisingColumns } from 'utils/constants';
import { FundingSeries } from 'api/company/company.model';
import Button from 'components/atoms/Button/Button';
import useCompanies from 'hooks/useCompanies';
import { useState } from 'react';
import Alert from 'components/atoms/Alert/Alert';

interface TableProps {
  fundraisingData: FundingSeries[];
}
const FundraisingTable: React.FC<TableProps> = ({ fundraisingData }) => {
  const [hasFetchedDeals, setHasFetchedDeals] = useState(false);
  const { fetchFundraisingData, company, noMoreDeals } = useCompanies();

  return (
    <div className="fundraising-data">
      <div className="fundraising-data__header">
        <h2 className="fundraising-data__title">Fundraising Data</h2>
      </div>
      <div className="fundraising-data__content">
        {fundraisingData.length > 0 ? (
          <>
            <TableContainer className="table" data-cy="fundraising-table">
              <MuiTable sx={{ minWidth: 200 }}>
                <TableHead data-cy="fundraising-table-head">
                  <TableRow>
                    {Object.values(FundraisingColumns).map((column, index) => (
                      <TableCell
                        key={index}
                        className={`table-cell table-head--${index}`}
                        data-cy={`fundraising-table-head-cell-${index}`}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody data-cy="fundraising-table-body">
                  {fundraisingData.map((row, index) => (
                    <TableRow key={index} data-cy={`fundraising-table-row-${index}`}>
                      <TableCell className="table-cell">{row.date}</TableCell>
                      <TableCell className="table-cell">
                        {row.amount?.amount
                          ? new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: row.amount.currency || 'USD',
                              minimumFractionDigits: 0,
                            }).format(parseInt(row.amount.amount, 10))
                          : 'N/A'}
                      </TableCell>
                      <TableCell className="table-cell">{row.round}</TableCell>
                      <TableCell className="table-cell">
                        {row.valuation?.amount
                          ? new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: row.valuation.currency || 'USD',
                              minimumFractionDigits: 0,
                            }).format(parseInt(row.valuation.amount, 10))
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MuiTable>
            </TableContainer>
            {!noMoreDeals && (
              <Button
                label="Get More Deals"
                customClass="button-deals"
                data-cy="more-deals-button"
                onClick={async () => {
                  if (!hasFetchedDeals) {
                    setHasFetchedDeals(true);
                  }
                  await fetchFundraisingData(company.snapshotId, company.id.toString());
                }}
              />
            )}
          </>
        ) : (
          <Alert title="No fundraising data available." />
        )}
      </div>
    </div>
  );
};

export default FundraisingTable;
